import React from "react"

const ToolTipIcon = () => {
    return (

    <svg enableBackground="new 0 0 21 21" height="25px" width="25px" preserveAspectRatio="xMidYMid meet" viewBox="0.0 0 1536.0 2048" zoomAndPan="magnify">
        <path d="M896,1504v-192c0-9.333-3-17-9-23s-13.667-9-23-9H672c-9.333,0-17,3-23,9s-9,13.667-9,23v192c0,9.333,3,17,9,23  s13.667,9,23,9h192c9.333,0,17-3,23-9S896,1513.333,896,1504z M1152,832c0-58.667-18.5-113-55.5-163s-83.167-88.667-138.5-116  s-112-41-170-41c-162,0-285.667,71-371,213c-10,16-7.333,30,8,42l132,100c4.667,4,11,6,19,6c10.667,0,19-4,25-12  c35.333-45.333,64-76,86-92c22.667-16,51.333-24,86-24c32,0,60.5,8.667,85.5,26s37.5,37,37.5,59c0,25.333-6.667,45.667-20,61  s-36,30.333-68,45c-42,18.667-80.5,47.5-115.5,86.5S640,1103.333,640,1148v36c0,9.333,3,17,9,23s13.667,9,23,9h192  c9.333,0,17-3,23-9s9-13.667,9-23c0-12.667,7.167-29.167,21.5-49.5c14.333-20.333,32.5-36.833,54.5-49.5  c21.333-12,37.667-21.5,49-28.5c11.333-7,26.667-18.667,46-35c19.333-16.333,34.167-32.333,44.5-48s19.667-35.833,28-60.5  S1152,861.333,1152,832z M1536,1024c0,139.333-34.333,267.833-103,385.5s-161.833,210.833-279.5,279.5S907.333,1792,768,1792  s-267.833-34.333-385.5-103S171.667,1527.167,103,1409.5S0,1163.333,0,1024s34.333-267.833,103-385.5S264.833,427.667,382.5,359  S628.667,256,768,256s267.833,34.333,385.5,103s210.833,161.833,279.5,279.5S1536,884.667,1536,1024z"/>
    </svg>
    )
}

export default ToolTipIcon;