import useForm from './useForm.js';
import React, { useState, useCallback, useEffect } from "react";
import validate from "../../utils/util-validator";
import ruleSet from './ruleSet.js';
import { navigate, Link } from "gatsby";
import Anchor from "@components/content/Anchor.jsx";
import ToolTip from '@components/content/tooltip';
import ToolTipIcon from '@components/content/tooltip/ToolTipIcon.jsx';
import { isEvokeScreenshooting, getEvokeScreenshotState } from '@src/helper';


// Style Imports
import "./Form.scss"
// Utility Imports
import { getVisitorId, getApiEndpoint} from '@src/helper';

// Content Imports


const SignupForm = () => {
    const preventFocus = () => {
        document.querySelectorAll('.form--signup input').forEach( field => {
            field.addEventListener('focus', (e) => {
                field.blur();
            });
        });
    }
    useEffect(()=>{
        const isEDMode = typeof window !== 'undefined' ? window.location.href.indexOf('ed=1') > 0 : false;
        if(isEDMode || isEvokeScreenshooting()){
            preventFocus();
        }
        values.enrollNow = "Y"; //Set enrollNow after page load. Might be a better place to set this using useState but then would need to change form hook.
	},[])
    const [showMailContainer, setShowMailContainer] = useState(false);

    const success = useCallback((vals) => {
        const submitButton = document.querySelector("button[type='submit']");
        submitButton.classList.add('button--submitting');
        fetch(getApiEndpoint() + 'psp/signup', {
            method: 'POST',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(vals),
        })
        .then(async (resp) => {
            var r = await resp.json();
            submitButton.classList.remove('button--submitting');
            if (r.success) {
                gtmFormSuccess();
                gtmFormFieldCompletionSubmitted();
                navigate('/enrollment-confirmation',
                {
                    state: {
                        success: true,
                        docusignURL: r.data.URL  
                    },
                });
                
            } else {

                navigate('/500');
            }
        })
        .catch((err) => {
            submitButton.classList.remove('button--submitting');
            navigate('/500');
        })
    }, [])



    const mailCheckboxOnClick = (e) =>{
        const checked = e.currentTarget.checked;
        if(checked){
            setShowMailContainer(true); 
        }
        else{
            setShowMailContainer(false);  
        }
        handleChange(e);
    }
    
    //GTM
    const gtmFormSuccess = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event' : 'Form Submission',
            'Event_Category': 'Form Submissions', 
            'Event_Action': 'Signup',
            'Event_Label': 'Complete'
        });
    }
    const gtmFormFieldError = (errors) => {
        if ( errors ) {
            for(var error in errors){
                const selector = ".error--" + error;
                if(document.querySelector(selector)){
                    const errorText = document.querySelector(selector).innerText;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'Form Errors',
                        'Event_Category': 'Validation Errors', 
                        'Event_Action': 'Signup',
                        'Event_Label': error + '_' + errorText
                    });
                }
                
            }
        }   
    }

    const gtmFieldCompletion = () => {
        document.querySelectorAll('.form--signup input, .form--signup select').forEach( field => {
            if(!field.getAttribute('data-has-ga-listener')){
                field.setAttribute('data-has-ga-listener','true')
                field.addEventListener('change', fieldCompletionEvent);
            }
        });
    }

    const fieldCompletionEvent = function(e) {
        const field = e.target;
        let fieldInfo;
        switch(field.type){
            case ('radio'):
                fieldInfo = (field.getAttribute('ga-val')) ? (field.name + '_' + field.getAttribute('ga-val')) : 'No ga-val found';
                break;
            case ('checkbox'):
                fieldInfo = field.name + '_' + field.checked;
                break;
            default:
                fieldInfo = field.name
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event' : 'Field Completion',
            'Event_Category': 'Form Field Completion', 
            'Event_Action': 'Signup',
            'Event_Label': fieldInfo
        });
    }
    
    const gtmFormFieldCompletionSubmitted = () => {
        document.querySelectorAll('.form--signup input, .form--signup select').forEach( field => {
            let fieldInfo;

            if ((field.type === 'radio' || field.type === 'checkbox') && !field.checked) return;
            if (field.value.length === 0) return;

            switch(field.type){
                case ('radio'):
                    fieldInfo = (field.getAttribute('ga-val')) ? (field.name + '_' + field.getAttribute('ga-val')) : 'No ga-val found';
                    break;
                case ('checkbox'):
                    fieldInfo = field.name + '_' + field.checked;
                    break;
                default:
                    fieldInfo = field.name
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event' : 'Form Field Completion_Form Submitted',
                'Event_Category': 'Form Field Completion_Form Submitted', 
                'Event_Action': 'Signup',
                'Event_Label': fieldInfo
            });
        })
    }

    useEffect(()=>{
        gtmFieldCompletion();
    })


    const {
        values,
        errors,
        handleChange,
        handleSubmit,
      } = useForm(success, validate, ruleSet.signup.rules, {source: 'aurinia_alliance_registration_2021', visitorId : getVisitorId(), optin: true}, ()=>{}, gtmFormFieldError );

    

    return (
            <form className="form--signup" onSubmit={handleSubmit}>
                <div className="single-col-input">
                    <label>
                        First Name*
                        <input type="text" name="firstName" value={values.firstName || ''} onChange={handleChange} aria-invalid={errors.firstName ? true : false} placeholder="First Name" maxLength={'50'}/>
                    {errors.firstName && (
                        <p className="error error--firstName">Please enter your first name</p>
                    )}  
                    </label>
                    <label>
                        Last Name*
                        <input type="text" name="lastName" value={values.lastName || ''} onChange={handleChange} aria-invalid={errors.lastName ? true : false} placeholder="Last Name" maxLength={'50'}/>
                    
                    {errors.lastName && (
                        <p className="error error--lastName">Please enter your last name</p>
                    )}
                    </label>
                    <label>
                        Email Address*
                        <input type="text" name="email" placeholder="e.g. jsmith@email.com" onChange={handleChange} aria-invalid={errors.email ? true : false} maxLength={'50'}/>
                    
                    {errors.email && (
                        <p className="error error--email">Please input a valid email address</p>
                    )}
                    </label>
                </div>

                
                <div className="phone-info">
                    <div className="single-col-input">
                        <label>
                            Phone Number*
                            <input type="text" name="phoneNumber" value={values.phoneNumber || ''} placeholder="Phone Number" onChange={handleChange} aria-invalid={errors.phoneNumber ? true : false} maxLength={'14'}/>
                        
                        {errors.phoneNumber && (
                            <p className="error error--phoneNumber">Please input a valid phone number (i.e.&nbsp;(555)&nbsp;555&#8209;5555)</p>
                        )}
                        </label>
                    </div>
                    <p className="disclaimer"><em>Aurinia Alliance will contact you from this number <span className="nowrap">1-833-AURINIA (1-833-287-4642)</span></em></p>
 
                    <div className="checkbox-wrapper">
                        <input name="receiveSMS" id="receiveSMS" type="checkbox" checked={values.receiveSMS || ''} onChange={handleChange} aria-invalid={errors.receiveSMS ? true : false} />
                        <label htmlFor="receiveSMS">I would like to receive information by text message (SMS) (optional)</label>
                    </div>
                    {errors.receiveSMS && (
                        <p className="error error--receiveSMS">Please confirm</p>
                    )}
                    <div className="agreement">
                        <p>By providing us with your mobile number, you agree to receive text messages to this number from Aurinia or our automated dialer. You understand that these texts may mention Aurinia's products or programs, but signing up for these texts is not required for you to access them.</p>
                        <p>I understand that I may opt out of receiving these communications by replying “STOP” at any time. Message and data rates may apply.</p>
                    </div>
                    <div className="radio-buttons radio-buttons--call" role='radiogroup' aria-labelledby="enrollNow-legend">
                        <p id="enrollNow-legend" className="legend kf-tooltip-positioner">Would you like to enroll online now?*</p>
                        <ToolTip ID={"enrollNowTooltip"} ariaLabel={"Toggle enrollment tool tip"} logo={<ToolTipIcon />}>You must complete the form to become fully enrolled. If you don't complete it right now, you'll be asked to at a later time.</ToolTip>
                        <div className="radio-wrapper">
                            <input name="enrollNow" id="enrollNowYes" type="radio" ga-val="1" value="Y" checked={values.enrollNow === "Y" || values.enrollNow !== "N"} onChange={handleChange} aria-invalid={errors.enrollNow ? true : false} />
                            <label htmlFor="enrollNowYes">Yes</label>
                        </div>
                        <div className="radio-wrapper">
                            <input name="enrollNow" id="enrollNowNo" type="radio" ga-val="2" value="N" checked={values.enrollNow === "N"} onChange={handleChange} aria-invalid={errors.enrollNow ? true : false} />
                            <label htmlFor="enrollNowNo">No</label>
                        </div>
                    </div>
                    <div className="single-col-input">
                        <label>
                            ZIP Code*
                            <input type="text" name="zipCodePhone" value={values.zipCodePhone || ''} placeholder="ZIP Code" onChange={handleChange} aria-invalid={errors.zipCodePhone ? true : false} maxLength={'5'}/>
                        {errors.zipCodePhone && (
                            <p className="error error--zipCodePhone">Please input a valid ZIP code (i.e.&nbsp;12345)</p>
                        )}
                                                </label>
                    </div>
                    <p className="disclaimer"><em>Your ZIP code enables us to pair you with a Nurse Case Manager in your area</em></p>
                </div>



                <div className="checkbox-wrapper">
                    <input name="receiveMail" id="receiveMailCheckbox" type="checkbox" checked={values.receiveMail || ''}  onChange={mailCheckboxOnClick} />
                    <label htmlFor="receiveMailCheckbox">I would like to receive information by mail (optional)</label>
                </div>
                {showMailContainer? (
                    <div className="single-col-input address-input">
                        <label>
                            Street Address 1*
                            <input type="text" name="streetAddress" value={values.streetAddress || ''} placeholder="Street address 1" onChange={handleChange} aria-invalid={errors.streetAddress ? true : false} maxLength={'50'}/>
                        </label>
                        {errors.streetAddress && (
                            <p className="error error--streetAddress">Please input a valid address</p>
                        )}  
                        <label>
                            Street Address 2 (optional)
                            <input type="text" name="streetAddress2" value={values.streetAddress2 || ''} placeholder="Street address 2" onChange={handleChange} maxLength={'50'}/>
                        </label>
                        
                        <label>
                            City*
                            <input type="text" name="city" value={values.city || ''} onChange={handleChange} placeholder="City" aria-invalid={errors.city ? true : false} maxLength={'50'}/>
                        </label>
                        {errors.city && (
                            <p className="error error--city">Please input a city</p>
                        )}

                        <label className="short-input">
                            State*
                            <select name="usState" value={values.usState || ''} onChange={handleChange} aria-invalid={errors.usState ? true : false}>
                                    <option value="">State</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District Of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                            </select>
                        </label>
                        {errors.usState && (
                            <p className="error error--usState">Please input a state</p>
                        )}
                        <label>
                            ZIP Code*
                            <input type="text" name="zipCodeAddress" value={values.zipCodeAddress || ''} placeholder="ZIP code" onChange={handleChange} aria-invalid={errors.zipCodeAddress ? true : false} maxLength={'5'}/>
                        {errors.zipCodeAddress && (
                            <p className="error error--zipCodeAddress">Please input a valid ZIP code (i.e.&nbsp;12345)</p>
                        )}
                        </label>
                    </div>
                ) : ''}
                <div className="agreement">
                    <p>By clicking submit below, you agree that the information you voluntarily provide may be used by Aurinia Pharma U.S., Inc. or other companies acting on its behalf to provide you with information about educational materials, resources, market research, approved treatment options, and programs/services about lupus nephritis. Aurinia will handle your personal information in accordance with Aurinia's Privacy Statement. For additional information, please read our <Anchor linkClass="blue-cta" link="https://www.auriniapharma.com/privacy-statement" isExternal={true} relationship="noopener noreferrer">Privacy Statement</Anchor>. You may <Link className="blue-cta" to="/unsubscribe">unsubscribe</Link> from our list at any time.</p>
                </div>
                <div className="checkbox-wrapper">
                    <input name="ageResident" id="ageResident" type="checkbox" checked={values.ageResident || ''} onChange={handleChange} />
                    <label htmlFor="ageResident" aria-invalid={errors.ageResident ? true : false} >By checking this box I certify that I am 18 years of age or older and I reside in the United States.*
                    {errors.ageResident && (
                        <p className="error error--ageResident">Please confirm</p>
                    )}
                    </label>
                </div>
                <button id="SignupSubmit" type="submit" className="btn btn--purple button--signup">COMPLETE ENROLLMENT</button>
            </form>
    )
}

export default SignupForm;
